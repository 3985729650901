div.main {
    
    &>div {
        position: fixed;
        top: 25vh;
        left: 0px;
        right: 0px;
        margin: auto;
        height: -moz-fit-content;
        height: fit-content;
        max-width: 70vw;
        min-width: 50vw;
        width: fit-content;

        &>div {
            width: 100%;
            position: relative;

            &>.message {
                position: absolute;
                bottom: -40px;
                left: 0;
                right: 0;
                margin: auto;
                text-align: center;
                color: #ff3f3f;
            }
        }
    }

    .card-element {
        padding: 16.5px 14px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-family: var(--font-code);
    }
    .card-element:focus {
        border-color: var(--secondary);
    }

    .trial-dates {
        font-family: var(--font-code);
        font-size: 11px;
        color: #aaa;
    }

    .billing-interval {

        p {
            text-align: center;
            font-size: 16px;
        }

        .billing-interval-toggle {
            margin: 10px auto;
        }

        .benefit {
            font-size: 11px;
            color: green;
            transform: translate(3px, 4px);
        }
    }
}       

.invoice-preview {
    margin: 0;
    margin-top: 20px;

    hr {
        background-color: #ccc;
        border: 0px;
        height: 1px;
        border-radius: 99px;
    }

    .position {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;

        span.green {
            color: green;
        }

        span.top {
            font-size: 15px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
            text-align: left;
        }

        span.sub {
            color: #aaa;
            font-size: 12px;
            text-align: left;
        }
    }
}
