#dashboard {
    width: 700px;
    margin: auto;

    h1 {
        margin-top: 0;
        font-size: 35px;
        text-align: left;
    }

    h2 {
        font-family: 20px;
        text-align: left;
    }

    p {
        font-size: 16px;
    }
}