#apiDocHeader {
    margin: 0;
    padding-top: 116px;
    position: unset;
    font-size: 35px;
    width: fit-content;
    // margin: 0 0 0 calc(25vw + 40px);
}

#apidoc {
    --border-color: #ccc;
    --background-color: #333;
    --blue: #002072;

    display: flex;

    #sidebar {
        border: 1px solid var(--border-color);
        border-style: none solid none none;
        position: fixed;
        left: 0;
        width: 25vw;
        // top: 70px;
        // height: calc(100vh - 71px);
        top: 0;
        height: 100vh;
        z-index: -1;
        padding: 125px 40px 10px;
        box-sizing: border-box;

        p {
            font-family: "Source Serif Pro", serif;
            // font-family: "JBMono";
            font-size: 17px;
            margin: 5px 0;
            cursor: pointer;
            transition: .1s;

            &:hover, &:active {
                transform: translateX(7px);
            }
        }

        hr {
            border: none;
            background-color: var(--border-color);
            height: 1px;
            margin: 20px 0;
        }
    }

    #content {
        position: fixed;
        overflow: auto;
        // top: 70px;
        // height: calc(100vh - 71px);
        top: 0;
        height: 100vh;
        z-index: -1;
        padding: 0px 40px 10px;
        box-sizing: border-box;

        width: 75vw;
        right: 0;
        padding-right: 25vw;

        a {
            scroll-behavior: smooth;
            color: var(--blue);
            font-weight: bold;
            text-decoration: none;
        }

        h3 {
            padding-top: 125px;
            font-size: 25px;
            margin-top: 0px;
            margin-bottom: 10px;
            font-family: "Source Serif Pro", serif;
        }

        code {
            font-family: "JBMono";
            font-size: 14px;
            display: block;
            padding: 7px 15px;
            margin: 5px 0;
            background-color: var(--background-color);
            color: #f1f1f1;
            border-radius: 5px;
        }
        
        p {
            font-size: 16px;
        }

        & > div {
            margin-bottom: 100px;
        }

        .example {
            display: flex;
            flex-direction: column;

            .options {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                border: 1px solid var(--border-color);
                border-style: none none solid none;
                margin-bottom: 10px;
                
                & > div {
                    border-radius: 5px 5px 0px 0px;
                    border: 1px solid transparent;
                    border-style: solid solid none solid;
                    margin-right: 2px;
                    padding: 7px 15px;
                    cursor: pointer;
                    font-family: "JBMono";
                    font-size: 14px;
                }

                & > div.active {
                    border-color: var(--border-color);
                }
            }

            .examples {

                & > div {
                    position: relative;
                    display: none;

                    &.active {
                        display: block;
                    }

                    & > div {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 10px;

                        span {
                            display: block;
                            width: 70px;
                            box-sizing: border-box;
                            font-family: "JBMono";
                            font-size: 12px;
                            text-transform: uppercase;
                            font-weight: bold;
                            text-align: right;
                            padding: 10px 15px 0 0;
                        
                            &::after {
                                content: ":";
                            }
                        }

                        .url, .result, .body {
                            width: calc(100% - 70px);
                            background-color: var(--background-color);
                            color: #f1f1f1;
                            font-family: "JBMono";
                            font-size: 13px;
                            box-sizing: border-box;
                            border-radius: 7px;
                            padding: 10px 15px;
                            white-space: pre-wrap;
                            tab-size: 2;
                        }
                    }
                }
            }
        }
    }
}